import React from 'react';
import '../style/app.css'

const HowTo = (props) => {

    return (
        <section className={props.theme}>
                <h2>Welcome to Peg Solitaire !</h2>
                    &emsp; &emsp; Peg Solitaire is a board game where player tries leave least amount of pegs on board while jumping pegs around. 
                    While there is a variety of board choice is out there, this one is the English version which has 33 holes and 32 pegs. <br/><br/>
                    <b>Here are the essentials to get you started:</b> <br/><br/>
                    &emsp; &emsp; <input type='radio' checked={true}/>&emsp;&emsp;A Peg<br/><br/>
                    &emsp; &emsp; <input type='radio' disabled={true} checked={true}/>&emsp;&emsp;Selected Peg<br/><br/>
                    &emsp; &emsp; <input type='radio'/>&emsp;&emsp;An Empty Slot<br/><br/>
                    &emsp; &emsp;To move the pegs, click on one of the blue-white colored pegs you want to move and click on one of the empty spot/s. And remember that you can only "jump" a peg. That means that there has to be another peg between the peg you choose and the spot you want to move to. <br/><br/>
                    <b>Valid Moves</b>
                    <ul>
                       <li>Vertical</li>
                       <li>Horizontal</li>
                    </ul><br/>
                    <b>Unvalid Moves</b>
                    <ul>
                       <li>Diagonal</li>
                    </ul><br/>
                    And lastly if you have any questions about the game or found any bugs you can use one of the contact channels to get in touch with me. Thank you for playing and have fun!
        </section>
    );
};

export default HowTo;