import React from 'react';
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, LineChart } from 'recharts';
import '../style/app.css';
import { Table , Responsive, Button } from 'semantic-ui-react'

const ScoresChart = (props) => {

	var data = []
	let localSCORES = []
	localSCORES.push(JSON.parse(localStorage.getItem("scores")))
		
	let localAVGSCORES = []
	localAVGSCORES.push(JSON.parse(localStorage.getItem("avgScore")))

	for(let i = 0; i < localSCORES[0].length ; i++){
		let solveCount = "Solve " + (i + 1);
		let avg = localAVGSCORES[0][i];
		let current = localSCORES[0][i];
		data.push({name: solveCount, Average: avg, Current: current})
	}

	return (
		<div>
			<Responsive minWidth={1001}>
					<ResponsiveContainer height={500} >
						<LineChart data={data} className={props.theme}
						margin={{ top: 5, right: 60, left: 10, bottom: 5 }}>
							<CartesianGrid strokeDasharray="2 2" stroke={'#00b5ad'} />
							<XAxis dataKey="name" tick={{ fill: '#157c77' }} stroke="#00b5ad"/> 
							<YAxis tick={{ fill: '#157c77' }} stroke="#00b5ad" />
							<Tooltip />
							<Legend />
							<Line type="monotone" dataKey="Average" stroke="#f79361" /> 
							<Line type="monotone" dataKey="Current" stroke="#00aeff" />
						</LineChart>
					</ResponsiveContainer>
					<br/>
					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell textAlign='center'>Best Score</Table.HeaderCell>
								<Table.HeaderCell textAlign='center'>Worst Score</Table.HeaderCell>	
							</Table.Row>
						</Table.Header>

						<Table.Body>
							<Table.Row>
								<Table.Cell textAlign='center'>{props.minScore()}</Table.Cell>
								<Table.Cell textAlign='center'>{props.maxScore()}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					<center><Button className={"resetButton"} onClick={() => {
						localStorage.clear();
						window.location.reload(true);
					}}>Reset</Button></center>
			</Responsive>

			<Responsive maxWidth={1000}> 
				<ResponsiveContainer height={500} >
					<LineChart data={data} className={props.theme}
					margin={{ top: 5, right: 60, left: 10, bottom: 5 }}>
						<CartesianGrid strokeDasharray="3 3" stroke={'#00b5ad'}/>
						<XAxis dataKey="name" tick={{ fill: '#157c77' }} stroke="#00b5ad"/>
						<YAxis tick={{ fill: '#157c77' }} stroke="#00b5ad" />
						<Tooltip />
						<Legend />
						<Line type="monotone" dataKey="Average" stroke="#f79361" /> 
						<Line type="monotone" dataKey="Current" stroke="#00aeff" />
					</LineChart>
				</ResponsiveContainer>
				<br/>
				<Table celled definition className={props.theme}>
						<Table.Row>
							<Table.Cell textAlign='center'>Best Score</Table.Cell>
							<Table.Cell textAlign='center'>{props.minScore()}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell textAlign='center'>Worst Score</Table.Cell>
							<Table.Cell textAlign='center'>{props.maxScore()}</Table.Cell>
						</Table.Row>
				</Table>
				<center><Button className={"resetButton"} onClick={() => {
					localStorage.clear();
					window.location.reload(true);
				}}>Reset</Button></center>
			</Responsive>
		</div>
	);
};

export default ScoresChart;
