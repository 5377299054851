import React, { Component } from 'react';
import '../style/app.css';
import { Button, Dimmer, Icon} from 'semantic-ui-react';
import firebase from "firebase";

var config = {
    apiKey: "AIzaSyAm2QW6Y7I1VXHJpTFGvjckesN7SQS5PPk",
    authDomain: "peg-solitaire-ca3a5.firebaseapp.com",
    databaseURL: "https://peg-solitaire-ca3a5.firebaseio.com",
    projectId: "peg-solitaire-ca3a5",
    storageBucket: "peg-solitaire-ca3a5.appspot.com",
    messagingSenderId: "242914333862"
};
firebase.initializeApp(config);

class Game extends Component {
    constructor(props){
        super(props);
        this.state = {
            clicked: [
                            true,true,true,
                            true,true,true,
                  true,true,true,true,true,true,true,
                  true,true,true,false,true,true,true,
                  true,true,true,true,true,true,true,
                            true,true,true,
                            true,true,true,
                ],
            disabled: Array(45).fill(false),
            firstItem: null,
            firstItemID: null,
            secondItem: null,
            secondItemID: null,
            rows: ["A","B","C","D","E","F","G"],
            firstCol: null,
            firstRow: null,
            secondRow: null,//
            currentROOM: null,
            gameHasFinished: false,
            activeUser: null,
            isBothReady: false,
            isFirstGame: true,
            p1ready: false,
            p2ready: false,
            boardDisabled: false,
            isMobile: false,
        }
        this.baseState = this.state
    }

    componentDidMount(){
        firebase.database().ref('rooms/').on('value',(snapshot) => {
            if(this.state.currentROOM !== null && !snapshot.hasChild(this.state.currentROOM) && this.state.isBothReady){      
                this.props.activateCM()
            }
            else if(this.state.currentROOM !== null && !snapshot.hasChild(this.state.currentROOM) && !this.state.isBothReady){ 
                this.props.activateCM()
            }
        })
        window.innerWidth <= 1000 ? this.setState({isMobile: true}) : this.setState({isMobile:false})
    }

    joinRoomID = (roomID) => {
        this.setState(this.baseState);
        this.setState({disabled: this.baseState.disabled})
        firebase.database().ref('rooms/').once('value', (snapshot) => {
            if(snapshot.hasChild(roomID)){
                this.setState({currentROOM : roomID},() => {
                    if(this.state.currentROOM != null){
                        this.fetchData(this.state.currentROOM)
                        firebase.database().ref('rooms/' + roomID).update({ 
                            p2HasJoined: true
                        });
                        this.checkRights(roomID);
                        this.props.updateRoom(roomID);
                        this.checkIfUsersJoined()
                    }  
                } )
            }
            else{
                alert("there is no room found with this id")
            }
        })
        this.checkIfUsersJoined()
    }

    createRoomID = () => { 
        var isMobile = this.state.isMobile
        this.setState(this.baseState);
        this.setState({disabled: this.baseState.disabled, isMobile: isMobile})
        var roomID;
        var letters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
        roomID =    String(letters[Math.floor(Math.random() * Math.floor(25))]) +  
                    String([Math.floor(Math.random() * Math.floor(9))])+
                    String(letters[Math.floor(Math.random() * Math.floor(25))]) +  
                    String([Math.floor(Math.random() * Math.floor(9))]);
        this.checkRoomExists(roomID);
        this.setState({currentROOM : roomID},() => {
            if(this.state.currentROOM != null){
                this.fetchData( this.state.currentROOM)  
                setTimeout(() => {                
                    firebase.database().ref('rooms/' + roomID ).update({
                        p1HasJoined: true,
                        hasRightsToPlay: 'p1',
                    });
                    this.checkIfUsersJoined()
                },1000)
            }  
        } )
        this.props.updateRoom(roomID);
    }

    checkRoomExists = (roomid) => { 
        firebase.database().ref('rooms/').once('value', (snapshot) => {
            if(!snapshot.hasChild(roomid)){ 
                this.writeData(roomid);
            }
        });
    }

    checkRights = (roomID) => {
        var rightsVal;  
        
        firebase.database().ref('rooms/' + roomID + '/hasRightsToPlay').on('value', (snapshot)=> {
            rightsVal = snapshot.val()
            
            if(rightsVal === 'p2' && this.props.joiner === true){
                this.testDisabled(0)
                this.setState({boardDisabled: false})
            }
            else if(rightsVal === 'p1' && this.props.joiner === true){
                this.testDisabled(1)
                this.setState({boardDisabled: true})
            }
            else if(rightsVal === 'p2' && this.props.creator === true){
                this.testDisabled(1)
                this.setState({boardDisabled: true})
            }
            else if(rightsVal === 'p1' && this.props.creator === true){
                this.testDisabled(0)
                this.setState({boardDisabled: false})
            }
        })
        this.setState({activeUser: rightsVal})
        return rightsVal;
    }

    writeData = (roomID) => {
        var right = this.checkRights(roomID)

        if(right === 'p1'){
            firebase.database().ref('rooms/' + roomID).update({
                hasRightsToPlay: 'p2'
            });
        }
        else if(right === 'p2'){
            firebase.database().ref('rooms/' + roomID).update({
                hasRightsToPlay: 'p1'
            });
        }
        else{ 
            firebase.database().ref('rooms/' + roomID).update({
                hasRightsToPlay: 'p1'
            });
        }

        firebase.database().ref('rooms/' + roomID).update({
            clickedPegs: this.state.clicked
        });

        this.checkLegalMoves();
    } 

    fetchData = (roomID) => {
        firebase.database().ref('rooms/' + roomID + '/clickedPegs').on('value', (snapshot) => {
            if(snapshot.val() != null){
                var arrayOfPegs = new Array();
                var objMap = new Map(Object.entries(snapshot.val()));
                objMap.forEach((item) => {
                    arrayOfPegs.push(item)
                })
                this.setState({clicked: arrayOfPegs})
                this.checkLegalMoves();
            }
            }
        );
    }

    testDisabled = (enable) => {
        var arrayOfDisabled = this.state.disabled
        if(enable === 1){
            for(let i = 0 ; i < this.state.disabled.length; i++){
                arrayOfDisabled[i] = true;   
            }
        }
        else if(enable === 0){
            for(let i = 0 ; i < this.state.disabled.length; i++){
                arrayOfDisabled[i] = false;   
            }
        }
        this.setState({
          disabled: arrayOfDisabled        
        })
    }

    checkIfUsersJoined = () => {
        firebase.database().ref('rooms/' + this.state.currentROOM ).on('value', (snapshot) => {
            if(snapshot.val() != null){
                var dbVal = snapshot.val()
                var user1Ready = dbVal.p1HasJoined
                var user2Ready = dbVal.p2HasJoined
                if(user1Ready === true && user2Ready=== true){
                    this.setState({isBothReady: true},() => {
                        firebase.database().ref('rooms/' + this.state.currentROOM).update({
                            p1HasJoined: false,
                            p2HasJoined: false
                        });
                    })
                }
            }
        });
    }
    
    checkIfUsersReady = () => {
        firebase.database().ref('rooms/' + this.state.currentROOM + '/p1ready').on('value',(snapshot) => {
            if(snapshot.val() === true){
                this.setState({p1ready: true})
                if(this.state.p1ready === true && this.state.p2ready === true){
                    this.continueCoopGame()
                    this.setState({gameHasFinished: false})
                    firebase.database().ref('rooms/' + this.state.currentROOM).update({
                        p1ready: false,
                        p2ready: false
                    }, () => this.setState({p1ready:false, p2ready:false}));
                }
            }
        })
        firebase.database().ref('rooms/' + this.state.currentROOM + '/p2ready').on('value',(snapshot) => {
            if(snapshot.val() === true){
                this.setState({p2ready: true})
                if(this.state.p1ready === true && this.state.p2ready === true){
                    this.continueCoopGame()
                    this.setState({gameHasFinished: false})
                    firebase.database().ref('rooms/' + this.state.currentROOM).update({
                        p1ready: false,
                        p2ready: false
                    }, () => this.setState({p1ready:false, p2ready:false}));
                }
            }
        })
    }

    selectReady = () => {
        if(this.props.creator){
            firebase.database().ref('rooms/' + this.state.currentROOM).update({
                p1ready: true
            }, () => this.checkIfUsersReady());
        }
        else if(this.props.joiner){
            firebase.database().ref('rooms/' + this.state.currentROOM).update({
                p2ready: true
            }, () => this.checkIfUsersReady());
        }
    }

    continueCoopGame = () => {
        firebase.database().ref('rooms/' + this.state.currentROOM + '/rightsChanged').once('value', (snapshot)=> { //rightsChanged
            if(!snapshot.hasChild('rightsChanged')){
                firebase.database().ref('rooms/' + this.state.currentROOM + '/hasRightsToPlay').once('value', (snapshot)=> {
                    if(snapshot.val() === 'p1'){
                        firebase.database().ref('rooms/' + this.state.currentROOM).update({
                            rightsChanged: 1,
                            hasRightsToPlay: 'p2'
                        });
                    }
                    else if(snapshot.val() === 'p2'){
                        firebase.database().ref('rooms/' + this.state.currentROOM).update({
                            rightsChanged: 1,
                            hasRightsToPlay: 'p1'
                        });
                    }
                })
            }
        });

        firebase.database().ref('rooms/').once('value', (snapshot) => {
            if(snapshot.val() === this.state.currentROOM){ 
                firebase.database().ref('rooms/' + this.state.currentROOM).update({
                    clickedPegs: this.state.clicked,
                });
            }
        });
    }

    cancelCoopGame = () => {
        firebase.database().ref('rooms/' + this.state.currentROOM).remove();
        this.finishState()
        this.testDisabled(0)
        window.innerWidth <= 1000 ? this.setState({isMobile: true}) : this.setState({isMobile:false})
    }

    checkLegalMoves = () => {
        var activePegs = this.state.clicked.slice();
        var rows = this.state.rows.slice();
        var legalMoveCount = 0;
        for(let i = 0 ; i < 33 ; i ++){    
            if(activePegs[i] === true){
                let c = i-1;
                let d = i-2;
                if(activePegs[i - 1] === true && activePegs[i - 2] === false && this.rowSelector(i) === this.rowSelector(c) && this.rowSelector(c) === this.rowSelector(d)){//to the left
                    legalMoveCount++;
                    break;
                }

                let a = i+1;
                let b = i+2;
                if(activePegs[i + 1] === true && activePegs[i + 2] === false && this.rowSelector(i) === this.rowSelector(a) && this.rowSelector(a) === this.rowSelector(b)){//to the right
                    legalMoveCount++;
                    break;
                }

                var colOfSelected = this.colSelector(i);
                var rowOfSelected = this.rowSelector(i);
                var idxOfSelectedRow = this.state.rows.indexOf(rowOfSelected)
                var nameOfRowAboveTheSelected = rows[idxOfSelectedRow - 1];
                var nameOfRowBelowTheSelected = rows[idxOfSelectedRow + 1];
                var idOfOneAbove =  this.idSelector(nameOfRowAboveTheSelected, colOfSelected);
                var colOfOneAbove = this.colSelector(idOfOneAbove)
                var idOfOneBelove = this.idSelector(nameOfRowBelowTheSelected, colOfSelected)
                var colOfOneBelove = this.colSelector(idOfOneBelove)

                if(activePegs[idOfOneAbove] === true ){
                    var rowNameTwoAbove = rows[idxOfSelectedRow - 2];
                    var idOfTwoAbove = this.idSelector(rowNameTwoAbove, colOfSelected);
                    var colOfTwoAbove = this.colSelector(idOfTwoAbove);
                    if(activePegs[idOfTwoAbove] === false && rowOfSelected !== nameOfRowAboveTheSelected !== rowNameTwoAbove && colOfTwoAbove === colOfOneAbove && colOfOneAbove === colOfSelected){ 
                        legalMoveCount++;
                        break;
                    }
                }
                if(activePegs[idOfOneBelove] === true ){
                    var rowNameTwoBelow = rows[idxOfSelectedRow + 2];
                    var idOfTwoBelow = this.idSelector(rowNameTwoBelow, colOfSelected);
                    var colOfTwoBelow = this.colSelector(idOfTwoBelow); 
                    if(activePegs[idOfTwoBelow] === false  && nameOfRowBelowTheSelected !== rowNameTwoBelow && colOfTwoBelow === colOfOneBelove && colOfOneBelove === colOfSelected ){ 
                        legalMoveCount++;
                        break;
                    }
                }  
            }
        }
        if(legalMoveCount === 0){
            var lastRoom = this.state.currentROOM
            this.finishState()
            this.setState({
                gameHasFinished:true,
                currentROOM: lastRoom,
                isFirstGame: false,
                isBothReady: false
            })

            if(this.state.currentROOM !== null){
                this.props.gameStateUpdate(this.state.activeUser)
                this.continueCoopGame()
                this.checkIfUsersReady()
            }
            else if(this.state.currentROOM === null){
                this.singlePlayerFinish()
            }
        }
    }

    clicked = (val,id,row,col) => {
        let clickedItemId = document.getElementById(id).value;

        let disableSelected = this.state.disabled.slice();
        disableSelected[val - 1] = true;
        this.setState({disabled: disableSelected});

        if(this.state.firstItem === null){ 
            this.setState({
                firstItemID: val-1,
                firstItem: (clickedItemId),
                firstCol: col,
                firstRow: row
            });
        }
        else if(this.state.secondItem === null) { 
            let secondItemID = val-1;
            let secondRoww = row;
            this.setState({
               secondItemID: val - 1,
                secondRow: row
            });

            let cloned6 = this.state.clicked.slice();
                if(this.state.clicked[val-1] === false){

                    if(this.state.firstRow === row){

                        if(this.state.firstItemID < secondItemID && (secondItemID-this.state.firstItemID === 2) ){ 
                            if(this.state.clicked[this.state.firstItemID+1] === true){
                                let gonnaDeleteID = this.state.firstItemID + 1;
                                cloned6[gonnaDeleteID] = false;
                                cloned6[this.state.firstItemID] = false; 
                                cloned6[secondItemID] = true; 
                                this.setState({
                                    clicked: cloned6
                                }, this.state.currentROOM !== null ? () => this.writeData(this.state.currentROOM) : () => this.checkLegalMoves());
                            }
                        }
                        else if(secondItemID < this.state.firstItemID && (this.state.firstItemID - secondItemID === 2)){
                            if(this.state.clicked[this.state.firstItemID - 1] === true){
                                let gonnaDeleteID = this.state.firstItemID - 1;
                                cloned6[gonnaDeleteID] = false;
                                cloned6[this.state.firstItemID] = false; 
                                cloned6[secondItemID] = true; 
                                this.setState({
                                    clicked: cloned6
                                },this.state.currentROOM !== null ? () => this.writeData(this.state.currentROOM) : () => this.checkLegalMoves()); 
                            }
                        }

                    }

                    else{ 
                        let firstRow = this.state.firstRow;
                        let secondRow = secondRoww;
                        let firstClickedIndex = this.state.rows.indexOf(firstRow);
                        let secondClickedIndex = this.state.rows.indexOf(secondRow);

                        var firstClickedCol = this.colSelector(this.state.firstItemID);
                        var secondClickedCol = this.colSelector(secondItemID)

                        if(firstClickedIndex < secondClickedIndex && (secondClickedIndex-firstClickedIndex === 2 && firstClickedCol === secondClickedCol) ){
                            let firstCol = this.state.firstCol;
                            let firstClickedIndex = this.state.rows.indexOf(firstRow);
                            let middleOneIndex = firstClickedIndex + 1;
                            let gonnaDeleteRow = this.state.rows[middleOneIndex];
                            let gonnaDeleteCol = firstCol;

                            let gonnaDelete = this.idSelector(gonnaDeleteRow,gonnaDeleteCol);
                            if(this.state.clicked[gonnaDelete] === true){
                                cloned6[this.state.firstItemID] = false;
                                cloned6[gonnaDelete] = false;
                                cloned6[val-1] = true;
                                this.setState({
                                    clicked: cloned6
                                }, this.state.currentROOM !== null ? () => this.writeData(this.state.currentROOM) : () => this.checkLegalMoves());
                            }

                        }
                        else if (firstClickedIndex - secondClickedIndex === 2 && firstClickedCol === secondClickedCol){
                            let firstRowDUP = this.state.firstRow;
                            let firstColDUP = this.state.firstCol;
                            let firstClickedIndexDUP = this.state.rows.indexOf(firstRowDUP);
                            let middleOneIndexDUP =  firstClickedIndexDUP - 1;
                            let gonnaDeleteRowDUP = this.state.rows[middleOneIndexDUP];
                            let gonnaDeleteColDUP = firstColDUP;

                            let gonnaDeleteDUP = this.idSelector(gonnaDeleteRowDUP,gonnaDeleteColDUP);
                            if(this.state.clicked[gonnaDeleteDUP] === true){
                                cloned6[this.state.firstItemID] = false;
                                cloned6[gonnaDeleteDUP] = false;
                                cloned6[val-1] = true;
                                this.setState({
                                    clicked: cloned6
                                }, this.state.currentROOM !== null ? () => this.writeData(this.state.currentROOM) : () => this.checkLegalMoves());
                            }
                        }
                    }
                }    

            let cloned4 = this.state.disabled.slice();
            for(let i=0;i<=44;i++){
                if(this.state.disabled[i] === true){
                    cloned4[i] = false;
                    this.setState({disabled: cloned4});
                    this.setState({secondItem: (clickedItemId)});
                }
                else {
                    this.setState({
                        firstItem: null,
                        secondItem: null
                    });
                }
                
            }
        } 
    }

    idSelector = (row,col) => {
        let startVal;

        if(row === 'A'){
            startVal = -3;
        }
        else if (row === 'B'){
            startVal = 1;
        }
        else if(row === 'C'){
            startVal = 6;
        }
        else if(row === "D"){
            startVal = 13;
        }
        else if (row === "E"){
            startVal = 20;
        }
        else if (row === "F"){
            startVal = 25;
        }
        else {
            startVal = 30;
        }

        return((startVal + col) - 1);
    }

    rowSelector = (id) => {
        if(2 >= id && id >= 0){
            return 'A'; 
        }
        else if(5 >= id && id >= 3){
            return 'B'; 
        }
        else if(12 >= id && id >= 6){
            return 'C'; 
        }
        else if(19 >= id && id >= 13){
            return 'D'; 
        }
        else if(26 >= id && id >= 20){
            return 'E'; 
        }
        else if(29 >= id && id >= 27){
            return 'F'; 
        }
        else if(32 >= id && id >= 30){
            return 'G'; 
        }
    }

    colSelector = (id) => {

        if(id === 6 || id === 13 || id === 20){
            return 1;
        }
        else if(id === 7 || id === 14 || id === 21){
            return 2;
        }
        else if(id === 0 || id === 3 || id === 8 || id === 15 || id === 22 || id === 27 || id === 30){
            return 3;
        }
        else if(id === 1 || id === 4 || id === 9 || id === 16 || id === 23 || id === 28 || id === 31){
            return 4;
        }
        else if(id === 2 || id === 5 || id === 10 || id === 17 || id === 24 || id === 29 || id === 32){
            return 5;
        }
        else if(id === 11 || id === 18 || id === 25){
            return 6;
        }
        else if(id === 12 || id === 19 || id === 26){
            return 7;
        }
    }

    finishState = () =>{
        this.setState(this.baseState);
        this.setState({disabled: this.baseState.disabled})
        window.innerWidth <= 1000 ? this.setState({isMobile: true}) : this.setState({isMobile:false})
    }

    singlePlayerFinish = () => {
        let counter = 0;
        for(let i =0; i<=this.state.clicked.length;i++){
            if(this.state.clicked[i] === true){
                counter = counter + 1;
            }
        }

        if(!localStorage.hasOwnProperty("scores")){
            let localScore = [];
            localScore.push(counter)
            localStorage.setItem("scores", JSON.stringify(localScore))
        }
        else{ 
            let localScore = [];
            let parsed = JSON.parse(localStorage.getItem("scores"))
            localScore = parsed
            localScore.push(counter)
            localStorage.setItem("scores", JSON.stringify(localScore))
        }

        var tempBest = this.props.bestScore(); 
        if(!localStorage.hasOwnProperty("bestScore")){
            localStorage.setItem("bestScore",JSON.stringify(tempBest));
            
        }
        else{
            if(localStorage.getItem("bestScore") > tempBest){
                localStorage.setItem("bestScore",JSON.stringify(tempBest))
                this.props.newBest()
            }
        }
        this.props.avgScore();
        this.setState(this.baseState);
        this.setState({disabled: this.baseState.disabled})
        window.innerWidth <= 1000 ? this.setState({isMobile: true}) : this.setState({isMobile:false})
    }

    render(){
        document.body.style.background = this.props.theme === 'dark' ? '#2e2e2e' : '#d4d4d4' 

        return(
            <div className="radioButtons">
                {this.state.isFirstGame ?                 
                    <Dimmer active={this.state.currentROOM !== null && this.state.isBothReady === false && this.state.isFirstGame}>
                        {this.state.isMobile ?
                        <p>Your room ID is: <span className={"rid-text"}>{this.state.currentROOM}</span><br/><br/>
                        Awaiting for players: <Icon loading name={'spinner'} /> <br/><br/>
                        <Button color='red' onClick={() => this.props.cancelCoop()}>Cancel</Button></p>
                        : <p>Awaiting for players: <Icon loading name={'spinner'} /></p>}
                    </Dimmer> :

                    <Dimmer active={this.state.currentROOM !== null && this.state.gameHasFinished && !this.state.isFirstGame}>
                        <p>Do you want to play again ?</p>
                        <p>Player1:<Icon loading={!this.state.p1ready} name={!this.state.p1ready ? 'spinner' : 'checkmark'} /></p>
                        <p>Player2:<Icon loading={!this.state.p2ready} name={!this.state.p2ready ? 'spinner' : 'checkmark'} /></p>
                        <Button color='red' onClick={() => this.props.cancelCoop()}>Cancel</Button>
                        <Button onClick={() => this.selectReady()}>Ready</Button>
                    </Dimmer>
                }
                <br/>
                <input type="radio" id = "A1" checked={this.state.clicked[0]} onClick={() => this.clicked(1,"A1","A",3)} disabled={this.state.disabled[0]} />
                <input type="radio" id = "A2" checked={this.state.clicked[1]} onClick={() => this.clicked(2,"A2","A",4)} disabled={this.state.disabled[1]} />
                <input type="radio" id = "A3" checked={this.state.clicked[2]} onClick={() => this.clicked(3,"A3","A",5)} disabled={this.state.disabled[2]} />
                <br/>
                <input type="radio" id = "B1" checked={this.state.clicked[3]} onClick={() => this.clicked(4,"B1","B",3)} disabled={this.state.disabled[3]} />
                <input type="radio" id = "B2" checked={this.state.clicked[4]} onClick={() => this.clicked(5,"B2","B",4)} disabled={this.state.disabled[4]} />
                <input type="radio" id = "B3" checked={this.state.clicked[5]} onClick={() => this.clicked(6,"B3","B",5)} disabled={this.state.disabled[5]} />
                <br/>
                <input type="radio" id = "C1" checked={this.state.clicked[6]}  onClick={() => this.clicked(7, "C1","C",1)} disabled={this.state.disabled[6]} />
                <input type="radio" id = "C2" checked={this.state.clicked[7]}  onClick={() => this.clicked(8, "C2","C",2)} disabled={this.state.disabled[7]} />
                <input type="radio" id = "C3" checked={this.state.clicked[8]}  onClick={() => this.clicked(9, "C3","C",3)} disabled={this.state.disabled[8]} />
                <input type="radio" id = "C4" checked={this.state.clicked[9]}  onClick={() => this.clicked(10,"C4","C",4)} disabled={this.state.disabled[9]} />
                <input type="radio" id = "C5" checked={this.state.clicked[10]} onClick={() => this.clicked(11,"C5","C",5)} disabled={this.state.disabled[10]} />
                <input type="radio" id = "C6" checked={this.state.clicked[11]} onClick={() => this.clicked(12,"C6","C",6)} disabled={this.state.disabled[11]} />
                <input type="radio" id = "C7" checked={this.state.clicked[12]} onClick={() => this.clicked(13,"C7","C",7)} disabled={this.state.disabled[12]} />
                <br/>
                <input type="radio" id = "D1" checked={this.state.clicked[13]} onClick={() => this.clicked(14,"D1","D",1)} disabled={this.state.disabled[13]} />
                <input type="radio" id = "D2" checked={this.state.clicked[14]} onClick={() => this.clicked(15,"D2","D",2)} disabled={this.state.disabled[14]} />
                <input type="radio" id = "D3" checked={this.state.clicked[15]} onClick={() => this.clicked(16,"D3","D",3)} disabled={this.state.disabled[15]} />
                <input type="radio" id = "D4" checked={this.state.clicked[16]} onClick={() => this.clicked(17,"D4","D",4)} disabled={this.state.disabled[16]} />
                <input type="radio" id = "D5" checked={this.state.clicked[17]} onClick={() => this.clicked(18,"D5","D",5)} disabled={this.state.disabled[17]} />
                <input type="radio" id = "D6" checked={this.state.clicked[18]} onClick={() => this.clicked(19,"D6","D",6)} disabled={this.state.disabled[18]} />
                <input type="radio" id = "D7" checked={this.state.clicked[19]} onClick={() => this.clicked(20,"D7","D",7)} disabled={this.state.disabled[19]} />
                <br/>
                <input type="radio" id = "E1" checked={this.state.clicked[20]} onClick={() => this.clicked(21,"E1","E",1)} disabled={this.state.disabled[20]} />
                <input type="radio" id = "E2" checked={this.state.clicked[21]} onClick={() => this.clicked(22,"E2","E",2)} disabled={this.state.disabled[21]} />
                <input type="radio" id = "E3" checked={this.state.clicked[22]} onClick={() => this.clicked(23,"E3","E",3)} disabled={this.state.disabled[22]} />
                <input type="radio" id = "E4" checked={this.state.clicked[23]} onClick={() => this.clicked(24,"E4","E",4)} disabled={this.state.disabled[23]} />
                <input type="radio" id = "E5" checked={this.state.clicked[24]} onClick={() => this.clicked(25,"E5","E",5)} disabled={this.state.disabled[24]} />
                <input type="radio" id = "E6" checked={this.state.clicked[25]} onClick={() => this.clicked(26,"E6","E",6)} disabled={this.state.disabled[25]} />
                <input type="radio" id = "E7" checked={this.state.clicked[26]} onClick={() => this.clicked(27,"E7","E",7)} disabled={this.state.disabled[26]} />
                <br/>
                <input type="radio" id = "F1" checked={this.state.clicked[27]} onClick={() => this.clicked(28,"F1","F",3)} disabled={this.state.disabled[27]} />
                <input type="radio" id = "F2" checked={this.state.clicked[28]} onClick={() => this.clicked(29,"F2","F",4)} disabled={this.state.disabled[28]} />
                <input type="radio" id = "F3" checked={this.state.clicked[29]} onClick={() => this.clicked(30,"F3","F",5)} disabled={this.state.disabled[29]} />
                <br/>
                 <input type="radio" id = "G1" checked={this.state.clicked[30]} onClick={() => this.clicked(31,"G1","G",3)} disabled={this.state.disabled[30]} />
                <input type="radio" id = "G2" checked={this.state.clicked[31]} onClick={() => this.clicked(32,"G2","G",4)} disabled={this.state.disabled[31]} />
                <input type="radio" id = "G3" checked={this.state.clicked[32]} onClick={() => this.clicked(33,"G3","G",5)} disabled={this.state.disabled[32]} />  
                <br/>
                <br/>
                {this.state.currentROOM === null ? <Button size={this.state.isMobile ? "small" : "medium"} className={"finishButton"} onClick={() => this.singlePlayerFinish()} size={'medium'}> Finish & Reset</Button>: null}
                {this.state.currentROOM !== null ? <Button color='red' onClick={() => this.props.cancelCoop()} size={'medium'}> Cancel Coop</Button>: null}
                {this.state.currentROOM !== null ? <Button color='teal' disabled={this.state.boardDisabled === true ? true : false} onClick={() => this.writeData(this.state.currentROOM)} size={'medium'}> Skip Move </Button>: null}
            </div>
        );
    }
}

export default Game;

/* 
    Special thanks to a friend of mine Berkcan for being one of the testers 
    and scoring an amazing 26 points(!) ^^ 
*/