import React, {Component} from 'react';
import Game from './Game';
import ScoresChart from './ScoresChart';
import HowTo from './HowTo';
import '../style/app.css';
import '../index.css';
import { Grid,  Segment, Tab, Button, Icon, Popup, Transition, Responsive, Divider, Input, Dimmer, Modal, Menu, Dropdown} from 'semantic-ui-react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class App extends Component{
    constructor(props){
        super(props);
        this.state = {
            color: 'teal',
            theme: 'dark',
            iconTheme: 'moon outline',
            panes: [ 
                { menuItem: () =>  <Menu.Item onClick={() => this.setState({tabIndex: 0})}>Play</Menu.Item>, render: () =>  
                                                        <Tab.Pane color={'teal'} attached={false} className={this.state.theme}>
                                                            <Segment key="1"
                                                                className='inner'
                                                                content={<Game updateRoom = {this.updateRoom} 
                                                                                ref={ref => this.child = ref} 
                                                                                newBest= {this.toggleVisibility} 
                                                                                theme={this.state.theme} 
                                                                                bestScore={this.minScoreCheck} 
                                                                                avgScore={this.avgScoreCheck} 
                                                                                creator = {this.state.isCreator}
                                                                                joiner = {this.state.isJoiner} 
                                                                                gameStateUpdate = {this.gameState}
                                                                                cancelCoop = {this.cancelCoop}
                                                                                activateCM = {this.activateCoopModal}
                                                                                 />}>
                                                                                                        
                                                            </Segment>
                                                        </Tab.Pane>
                                                    },
                { menuItem: () =>  <Menu.Item onClick={() => this.setState({tabIndex: 1})}>Scores</Menu.Item>, render : () => <Tab.Pane name='scores' color={'teal'} attached={false} className={this.state.theme}>
                                                                <Segment  
                                                                         className='inner'
                                                                         content={!localStorage.hasOwnProperty("scores") ? <div className={"e-score-chart" + "-" + this.state.theme}><h1 className={"emptyscoretext"}> We couldn't find any finished games.<br/><br/> Please check again after doing at least a solve. </h1><Button size={'small'} className={"emptyscorebutton"} onClick={() => this.setState({tabIndex: 0})}>Return To Game</Button></div>  
                                                                         : <ScoresChart theme={this.state.theme} minScore={this.minScoreCheck} maxScore={this.maxScoreCheck}/>}>
                                                                </Segment>
                                                            </Tab.Pane> },

                { menuItem: () =>  <Menu.Item onClick={() => this.setState({tabIndex: 2})}>Instructions</Menu.Item>, render: () => <Tab.Pane color={'teal'} attached={false} className={this.state.theme}>
                                                              <Segment  
                                                                       className='inner'
                                                                       content={<HowTo theme={this.state.theme}/>}
                                                              >       
                                                              </Segment>
                                                           </Tab.Pane> },

                { menuItem:  () =>  (    
                                    <Popup trigger={<Button disabled={this.state.tabIndex !== 0 ? true : false} className={"coop-button"}>Co-Op</Button>} on={['hover', 'click']} hoverable={true} position={'bottom right'} onMount={() => this.setState({transitionPopup: true})} onUnmount={() => this.setState({transitionPopup:false})}>  
                                        <Transition visible={this.state.transitionPopup} animation='scale' duration={300}>
                                        <Dimmer.Dimmable blurring dimmed={this.state.coopActive}>
                                            <Segment basic textAlign='center'>
                                                <h4>Join a game</h4>
                                                <Input
                                                type = 'text'
                                                icon='search'
                                                iconPosition='left'
                                                placeholder='Game ID' 
                                                onChange = { evt => this.joinRoomID(evt)}
                                                />
                                                <Divider horizontal>Or</Divider>
                                                <Button onClick = {() => this.createRoomID()} color='teal' content='Create New Game' icon='add' labelPosition='left' />
                                            </Segment>
                                            <Dimmer active={this.state.coopActive}>
                                                <CopyToClipboard 
                                                    text={this.state.currentRoom} 
                                                    onCopy={() => this.setState({popupIsOpen: true})}>
                                                    <h4 className={"room-id"}>Your room ID is <span className={"rid-text"}>{this.state.currentRoom}</span></h4>
                                                </CopyToClipboard>
                                                {this.child ? (this.child.state.isBothReady ? null : <Button onClick={() => this.cancelCoop()} color={'red'}>Cancel Room</Button> ) : null}
                                            </Dimmer>
                                        </Dimmer.Dimmable>  
                                        </Transition>
                                    </Popup>
                                )},
            ],            
            tabIndex: 0,
            menuIndex: 0,            
            transition: false,
            transitionPopup: false,
            coopModelShown: false,
            visible: false,
            coopActive: false,
            currentRoom: null,
            isCreator: false,
            isJoiner: false,
            gameHasFinished: false,
            playerWon: false,
            tabIndex: 0,
            p1HasJoined: false,
            p2HasJoined: false,
            p1readyIcon: 'spinner',
            p2readyIcon: 'spinner',
            coopCancelModal: false
        }
        this.baseState = this.state
    }

    activateCoopModal = () => {this.setState({coopCancelModal: true})}
    
    disableCoopModal = () => {this.setState({coopCancelModal: false})}

    cancelCoop = () => { 
        this.setState(this.baseState);
        this.setState({transition: true})
        if(this.child){
            this.child.cancelCoopGame()
        }
        this.enableMenuButtons()
    }

    gameState = (activeUser) => {
        var lastUser;
        if(activeUser === "p1"){
            lastUser = "p2"
        }
        else if(activeUser === "p2"){
            lastUser = "p1"
        }

        if(lastUser === "p1" && this.state.isCreator === true){
            this.setState({playerWon: true})
        }
        else if(lastUser === "p2" && this.state.isJoiner === false){
            this.setState({playerWon: true})
        }
        else{
            this.setState({playerWon: false})
        }

        if(this.state.gameHasFinished === false){
            this.setState({gameHasFinished: true})
        }
        else{
            this.setState({gameHasFinished: false})
        }
    }
    
    updateRoom = (roomid) => {
        this.setState({currentRoom: roomid})
    }

    joinRoomID = (evt) => {
        if(evt.target.value.length == 4){
            if(this.child){
                this.child.joinRoomID(evt.target.value);
                this.setState({coopActive: true, isJoiner: true, panes: this.disableMenuButtons(), p1HasJoined:true , p2HasJoined: true, coopModelShown: false})
            }
        }        
    }

    enableMenuButtons = () => {
        var panes = this.state.panes
        panes[1] = { menuItem:  <Menu.Item onClick={() => this.setState({tabIndex: 1})}>Scores</Menu.Item>, render : () => <Tab.Pane name='scores' color={'teal'} attached={false} className={this.state.theme}>
            <Segment className='inner'
                    content={!localStorage.hasOwnProperty("scores") ? <div className={"e-score-chart" + "-" + this.state.theme}><h1 className={"emptyscoretext"}> We couldn't find any finished games.<br/> Please check again after doing at least a solve.</h1><Button size={'small'} className={"emptyscorebutton"} onClick={() => this.setState({tabIndex: 0})}>Return To Game</Button></div>  : <ScoresChart theme={this.state.theme} minScore={this.minScoreCheck} maxScore={this.maxScoreCheck}/>}>
                </Segment>
            </Tab.Pane> 
        }
        panes[2] =  { menuItem: <Menu.Item onClick={() => this.setState({tabIndex: 2})}>Instructions</Menu.Item>, render: () => <Tab.Pane color={'teal'} attached={false} className={this.state.theme}>
            <Segment  
                    className='inner'
                    content={<HowTo theme={this.state.theme}/>}
            >       
            </Segment>
            </Tab.Pane> 
        }
        this.setState({panes: panes})
    }

    disableMenuButtons = () => {
        var panes = this.state.panes
        panes[1] = { menuItem:  <Menu.Item disabled={true}>Scores</Menu.Item>}
        panes[2] = { menuItem: <Menu.Item disabled={true}>Instructions</Menu.Item>}
        return panes
    }

    createRoomID = () => {
        if(this.child){
            this.child.createRoomID();
            this.setState({coopActive: true, isCreator: true, panes: this.disableMenuButtons(), p1HasJoined: true})
        }
        this.handleModalClose()
    }

    componentDidMount(){
        document.addEventListener('click', function(e) { if(document.activeElement.toString() === '[object HTMLButtonElement]'){ document.activeElement.blur(); } });
        this.setState({transition: true})

        window.addEventListener("beforeunload", (event) => {
            if(this.state.coopActive){
                event.preventDefault();
                event.returnValue = ""
                return ''
            }
        })

        window.addEventListener("unload",(event) => {
            if(this.state.coopActive){
                this.cancelCoop()
            }    
        })
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload")
        window.removeEventListener("unload")
    }

    avgScoreCheck = () => {
        let avg;
        var sum = 0;
        let localAVG = [];
        localAVG.push(JSON.parse(localStorage.getItem("scores")))

        for(let i = 0; i < localAVG[0].length; i++){
            sum += localAVG[0][i];
        }
        avg = sum / localAVG[0].length;

        if(!localStorage.hasOwnProperty("avgScore")){
            let localScore = [];
            localScore.push(Math.trunc(avg))
            localStorage.setItem("avgScore", JSON.stringify(localScore))
        }
        else{ 
            let localScore = [];
            let parsed = JSON.parse(localStorage.getItem("avgScore"))
            localScore = parsed
            localScore.push(Math.trunc(avg))
            localStorage.setItem("avgScore", JSON.stringify(localScore))
        }
    }

    minScoreCheck = () => {
        var min = 32;
        let localMIN = [];
        localMIN.push(JSON.parse(localStorage.getItem("scores")));

        for(let i = 0; i < localMIN[0].length;i++){
            if(localMIN[0][i] < min){
                min = localMIN[0][i];
            }
        }

        return min;
    }
    
    maxScoreCheck = () => {
        var max = 1;
        let localMAX = [];
        localMAX.push(JSON.parse(localStorage.getItem("scores")));

        for(let i = 0 ; i < localMAX[0].length ; i++){
            if(localMAX[0][i] > max){
                max = localMAX[0][i];
            }
        }
        return max;
    }

    toggleVisibility = () => this.setState({ visible: !this.state.visible })

    handleNotificationComplete = () => setTimeout(function() {this.setState({visible: false});}.bind(this),5000);

    handleModalOpen = () => this.setState({coopModelShown: true})
    handleModalClose = () => this.setState({coopModelShown: false})

    render(){
        document.body.style.background = this.state.theme === 'dark' ? '#2e2e2e' : '#d4d4d4'
        const { color } = this.state
        const { visible } = this.state

        return (
            <Transition visible={this.state.transition} animation='scale' duration={300}>
            <div>
                <Responsive as={Grid} minWidth={1001}>
                    <Modal basic size={'small'} dimmer={'blurring'} open={this.state.coopCancelModal} onClose={() => this.cancelCoop()}>
                            <Modal.Content>
                                <h3>Co-op game has been canceled by a player.</h3>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='green' onClick={() => this.cancelCoop()} inverted>
                                    <Icon name='checkmark' /> Return to Singleplayer
                                </Button>
                            </Modal.Actions>
                    </Modal>
                    <Grid columns={3} padded>
                        <Grid.Row stretched>
                            <Grid.Column width={3}> 
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Tab activeIndex={this.state.tabIndex} menu={{ color, inverted: true, attached: false, tabular: false}} panes={this.state.panes} /> 
                                
                                <Popup className={'popupTheme'} position='bottom center' 
                                    trigger={<Button className={'butontry'} 
                                            onClick={() => this.state.theme === 'dark' ? this.setState({theme: 'light', iconTheme: 'moon'}) : this.setState({theme: 'dark', iconTheme: 'moon outline'})}>
                                                <Icon fitted name={this.state.iconTheme} size='large'></Icon>
                                            </Button>} 
                                >
                                    Switch theme
                                </Popup>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row >
                            <Grid.Column width={3}>  
                            </Grid.Column>

                            <Grid.Column width={10}>
                                {this.state.tabIndex === 0 ?
                                <Segment className={"credits"}>Berkay Uckac&emsp;<Icon  name={'mail'} onClick={()=> window.open("mailto:berkayuckac@hotmail.com") }/> 
                                                                                 <Icon  name={'github'} onClick={()=> window.open("https://github.com/berkayuckac")}/> 
                                                                                 <Icon  name={'linkedin'} onClick={()=> window.open("https://www.linkedin.com/in/berkay-uckac/")}/> 
                                </Segment> : null}

                                <Transition onShow={this.handleNotificationComplete} visible={visible} animation='fade' duration={250}>
                                    <Segment className={'notification'}>You have beaten your best score! Check out your scores by clicking <a className={"scoreLink"} onClick={() => this.setState({tabIndex: 1})}>here</a></Segment>
                                </Transition>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Responsive>

                <Responsive maxWidth={1000}> 

                    <Modal basic size={'small'} dimmer={'blurring'} open={this.state.coopCancelModal} onClose={() => this.cancelCoop()}>
                        <Modal.Content>
                            <h3>Co-op game has been canceled by a player.</h3>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={() => this.cancelCoop()} inverted>
                                <Icon name='checkmark' /> Return to Singleplayer
                            </Button>
                        </Modal.Actions>
                    </Modal>

                    <Grid.Column width={10}>
                        <Modal size={'mini'} open={this.state.coopModelShown} onClose={() => this.handleModalClose()}>
                            <Modal.Content>
                                <Segment basic textAlign='center'>
                                    <h4>Join a game</h4>
                                    <Input
                                    type = 'text'
                                    icon='search'
                                    iconPosition='left'
                                    placeholder='Game ID' 
                                    onChange = { evt => this.joinRoomID(evt)}
                                    />
                                    <Divider horizontal>Or</Divider>
                                    <Button onClick = {() => this.createRoomID()} color='teal' content='Create New Game' icon='add' labelPosition='left' />
                                    <Icon onClick={() => this.handleModalClose()} className={"close"} name={'close'}/>
                                </Segment>
                            </Modal.Content>
                        </Modal>

                        <Menu pointing className={this.state.theme}>
                            <Menu.Item><Dropdown disabled={this.state.coopActive} icon={'bars'}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item text='Play' onClick={() => this.setState({menuIndex: 0})}/>
                                                <Dropdown.Item text='Scores' onClick={() => this.setState({menuIndex: 1})}/>
                                                <Dropdown.Item text='Instructions' onClick={() => this.setState({menuIndex: 2})}/>
                                                <Dropdown.Item disabled={this.state.menuIndex !== 0 ? true : false} text='Co-Op' onClick={() => this.handleModalOpen()}/>
                                                <Dropdown.Divider />
                                                <Dropdown.Header icon={this.state.iconTheme} content={'Switch Theme'} onClick={() => this.state.theme === 'dark' ? this.setState({theme: 'light', iconTheme: 'moon'}) : this.setState({theme: 'dark', iconTheme: 'moon outline'})}/>
                                            </Dropdown.Menu>
                                        </Dropdown>
                            </Menu.Item>
                        </Menu>

                        {
                            this.state.menuIndex === 0 ? <Segment  
                                            className='inner'
                                            content={<Game updateRoom = {this.updateRoom} 
                                            ref={ref => this.child = ref} 
                                            newBest= {this.toggleVisibility} 
                                            theme={this.state.theme} 
                                            bestScore={this.minScoreCheck} 
                                            avgScore={this.avgScoreCheck} 
                                            creator = {this.state.isCreator}
                                            joiner = {this.state.isJoiner} 
                                            gameStateUpdate = {this.gameState}
                                            cancelCoop = {this.cancelCoop}
                                            activateCM = {this.activateCoopModal}/>}>      
                                                        </Segment> : (

                            this.state.menuIndex === 1 ? <Segment  
                                            className='inner'
                                            content={!localStorage.hasOwnProperty("scores") ? <div className={"e-score-chart" + "-" + this.state.theme}><h1 className={"emptyscoretext"}> We couldn't find any finished games.<br/><br/>Please check again after doing at least a solve.</h1><Button size={'small'} className={"emptyscorebutton"} onClick={() => this.setState({menuIndex: 0})}>Return To Game</Button></div>  : <ScoresChart theme={this.state.theme} minScore={this.minScoreCheck} maxScore={this.maxScoreCheck}/>}>
                                                        </Segment> : (
                            this.state.menuIndex === 2 ? <Segment  
                                            className='inner'
                                            content={<HowTo theme={this.state.theme}/>}>       
                                                        </Segment> : null))
                        }

                        {this.state.menuIndex === 0 ?
                                <Segment className={"credits"}>Berkay Uckac&emsp;<Icon  name={'mail'} onClick={()=> window.open("mailto:mail@example.org") }/> 
                                                                                 <Icon  name={'github'} onClick={()=> window.open("https://github.com/berkayuckac")}/> 
                                                                                 <Icon  name={'linkedin'} onClick={()=> window.open("https://www.linkedin.com/in/berkay-uckac/")}/> 
                                </Segment> 
                                : null}

                    </Grid.Column>
                    <Transition onShow={this.handleNotificationComplete} visible={visible} animation='fade' duration={250}>
                        <Segment className={'notification'}>You have beaten your best score! Check out your scores by clicking <a className={"scoreLink"} onClick={() => this.setState({tabIndex: 1})}>here</a></Segment>
                    </Transition>
                </Responsive>
            </div>
            </Transition>
        );
    }
}

export default App;